<template>
  <div class="slider-wrapper" >

    <!-- if video -->
    <div class="justify-center slide slide-style-2 slider-video-bg d-flex align-center" data-black-overlay="6" v-if="showVideo">
        <v-container>
          <v-row align="center">
            <v-col cols="12">
              <div class="text-left inner pt_sm--40">
                <img v-if="$t('logo.logoLight')&& $t('logo.logoLight')!=='logo.logoLight'" :src="$t('logo.logoLight')" class="logo-header" />
                <span class="theme-color font-700" v-if="headerVideo.viewSubtitle">{{ $t('video.subtitle')}}</span>
                <h1 class="heading-title mt--20" v-if="headerVideo.viewTitle" v-html="$t('video.title')"></h1>
                <p class="description" v-if="headerVideo.viewDesc">
                  {{ $t('video.desc')}}
                </p>
                <a class="own-btn own-btn-yellow mt--30" v-if="activateBuyTicketButtons" :href="$t('ctaUrls.buySingleTicket')" target="_blank"><span>{{ $t('buttons.cta')  }}</span></a>
                <p class="color-white font-700 mt--10" v-if="activateBuyTicketButtons && presale && $t('pricing.2.SeasonalTicketsPromoText')">{{ $t('pricing.2.SeasonalTicketsPromoText')  }}</p>
              </div>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col class="row mt--40 mb--30">
              <img src="/images/icons/header/line.svg" />
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col class="row main_icons flex-row">
              <div>
                <img src="/images/icons/header/star-interactive.svg" />
              </div>
              <div>
                <img src="/images/icons/header/teddy-bear.svg" />
              </div>
              <div>
                <img src="/images/icons/header/outdoor.svg"  />
              </div>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col class="row main_icons_text">
              <div>
                <span>Interactive</span>
              </div>
              <div>
                <span>Family friendly</span>
              </div>
              <div>
                <span>Outdoors</span>
              </div>
            </v-col>
          </v-row>
        </v-container>
        <div class="video-background" >
          <span>
            <video muted="" autoplay="" playsinline="" loop="" poster="" v-if="headerVideo.src">
              <source :src="headerVideo.src"/>
            </video>
          </span>
        </div>
    </div>

    <!-- if slider -->
    <VueSlickCarousel
        v-bind="settings"
        class="slider-digital-agency slider-activation rn-slick-dot dot-light mb--0 color-white"
        v-if="headerImages.length">
      <div
          class="slide slide-style-2 fullscreen d-flex align-center bg_image"
          data-black-overlay="2"
          v-for="(slider, i) in headerImages"
          :key="i"
          :style="{ backgroundImage: 'url(' + slider.src + ')' }">

        <v-container>
          <v-row>
            <v-col cols="12">
              <div class="text-left inner pt_sm--40">
                <img v-if="$t('logo.logoLight')" :src="$t('logo.logoLight')" class="logo-header" />
                <span class="theme-color font-700" v-if="slider.viewSubtitle">{{ $t('headerImages.' + slider.id + '.subtitle')  }}</span>
                <h1 class="heading-title mt--20" v-if="slider.viewTitle" v-html="$t('headerImages.' + slider.id + '.title') "></h1>
                <p class="description" v-if="slider.viewDesc">
                  {{ $t('headerImages.' + slider.id + '.desc')  }}
                </p>
                <a class="own-btn own-btn-yellow mt--30" v-if="activateBuyTicketButtons" :href="$t('ctaUrls.buySingleTicket')" target="_blank"><span>{{ $t('buttons.cta')  }}</span></a>
                <p class="color-white font-700 mt--10" v-if="activateBuyTicketButtons && presale && $t('pricing.2.SeasonalTicketsPromoText')">{{ $t('pricing.2.SeasonalTicketsPromoText')  }}</p>
              </div>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col class="row mt--40 mb--30">
              <img src="/images/icons/header/line.svg" />
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col class="row main_icons flex-row">
              <div>
                <img src="/images/icons/header/star-interactive.svg" />
              </div>
              <div>
                <img src="/images/icons/header/teddy-bear.svg" />
              </div>
              <div>
                <img src="/images/icons/header/outdoor.svg"  />
              </div>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col class="row main_icons_text">
              <div>
                <span>Interactive</span>
              </div>
              <div>
                <span>Family friendly</span>
              </div>
              <div>
                <span>Outdoors</span>
              </div>
            </v-col>
          </v-row>
        </v-container>

      </div>
      <!-- End Single Slide  -->
    </VueSlickCarousel>

    </div>
</template>
<script>
import {getLandingPageSettings} from "@/scripts/settings";
import Banner from "@/components/slider/Banner.vue";
import VueSlickCarousel from "vue-slick-carousel";

export default {
  components: {Banner,VueSlickCarousel},

  data() {
    return {
      showVideo: false,
      showHeaderImage: false,
      headerVideo: null,
      headerImages: [],
      activateBuyTicketButtons: false,
      settings: {
        fade: false,
        dots: false,
        arrows: false,
        infinite: true,
        speed: 1000
      },
      seasonalTicketsPresale: null,
      presale: false
    };
  },
  mounted() {
    this.fetchSettings();
  },
  methods: {
    fetchSettings(){
      let landigpageName = this.$route.params.garden;
      if(!this.$route.params.garden){
         landigpageName = "corporate";
      }
      getLandingPageSettings(landigpageName).then((settings) => {
        if(settings.headerVideo){
          this.showVideo = true,
          this.headerVideo = settings.headerVideo;
        }else if(settings.headerImages){
          this.headerImages = settings.headerImages;
          this.showHeaderImage = true
        }
        this.activateBuyTicketButtons = settings.activateBuyTicketButtons;
        this.seasonalTicketsPresale = settings.seasonalTicketsPresale;
        this.setPresale();
      });
    },
    setPresale(){
      if(this.seasonalTicketsPresale && this.seasonalTicketsPresale.active==true){
        var currentDate = new Date().toJSON().slice(0,10) + 'T00:00:00+0200';
        var check = new Date(currentDate);
        var from = new Date(this.seasonalTicketsPresale.periodStart);
        var to   = new Date(this.seasonalTicketsPresale.periodEnd);
        if(check >= from && check <= to){
          this.presale = true;
        }
      }
    }
  },
};
</script>