<template>
  <!-- Start Gallery Area  -->
  <div class="rn-portfolio-area pb--140" id="attractions" >
    <v-container>
      <v-row>
        <v-col lg="12">
          <div class="text-center section-title pb--40 mb_sm--0">
            <span class="subtitle2">{{ $t('headers-top-text.attractions')  }}</span>
            <h2 class="heading-title">{{ $t('headers.attractions')}}</h2>
          </div>
        </v-col>
      </v-row>

      <!-- for bigger screens than mobile -->
      <v-row class="gallery-wrapper gallery-grid hidden-xs-only" id="animated-thumbnials">
        <!-- Start Single Gallery -->
        <a
            class="item-portfolio-static gallery image col-md-6 col-sm-6 col-lg-3 pr--0 mr--0 pl--0 ml--0"
            v-for="(image, imageIndex) in galleryContent"
            :key="imageIndex"
            @click="index = imageIndex"
        >
          <div class="portfolio-static">
            <div class="thumbnail-inner">
              <div class="thumbnail">
                <img :src="image.thumb" alt="Portfolio Images" />
              </div>
            </div>
          </div>
        </a>
        <!-- End Single Gallery -->
      </v-row>
      <CoolLightBox
          :items="galleryContent"
          :index="index"
          :fullScreen="true"
          :effect="'fade'"
          @close="index = null"
      >
      </CoolLightBox>


      <!-- for mobile screens -->
      <VueSlickCarousel
          v-if="galleryContent.length"
          v-bind="settings"
          class="rn-slick-activation portfolio-slick-activation item-fluid slick-dotted rn-slick-dot pr--0 mr--0 pl--0 ml--0 hidden-sm-and-up">
        <!-- Start Single Portfolio  -->
        <div class="im_portfolio" v-for="(image, imageIndex) in galleryContent" :key="imageIndex">
          <div class="thumbnail_inner">
            <div class="thumbnail">
                <img class="w-100" :src="image.thumb" alt="Portfolio Images" />
            </div>
          </div>
        </div>
        <!-- End Single Portfolio  -->
      </VueSlickCarousel>

      <div class="text-center mt--40">
        <p>
          <a :href="$t('ctaUrls.seeOnInstagram')" class="link_underline white--text" target="_blank" >{{$t('buttons.seeOnInstagram')}}</a>
        </p>
      </div>
    </v-container>
  </div>
  <!-- End Gallery Area  -->
</template>
<script>
import { getLandingPageSettings} from "../../scripts/settings";
import VLazyImage from "v-lazy-image/v2";
import VueSlickCarousel from "vue-slick-carousel";
export default {
  components: {VLazyImage, VueSlickCarousel},
  data() {
    return {
      index: null,
      mapContent: null,
      galleryContent: [],
      settings: {
        dots: true,
        arrows: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 770,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              arrows: true,
            },
          },
          {
            breakpoint: 599,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              arrows: true,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              arrows: true,
            },
          },
        ],
      },
    };
  },
  mounted() {
    this.fetchSettings();
  },
  methods: {
    fetchSettings(){
      getLandingPageSettings(this.$route.params.garden).then((settings) => {
        this.galleryContent = settings.galleryContent;
      });
    },
  },
};
</script>